<template>
	<Page name="hydrodesulfuration" 
	title="L'hydrodésulfuration"
	implementation="1965"
	tonnesHandled="360"
	processingTemperature="350"
	abstract="<p>Ces unités ont pour rôle de retirer le soufre des gazoles, au contact d'hydrogène sous haute pression.</p>"
	animation="static/animation/HYDRO.json">
	<picture slot="thumbnail">
		<img class="lazy"
		sizes="(max-width: 480px) 100vw, 480px"
		data-srcset="
		img/secteur/HD_ndpmig_c_scale_w_350.jpg 350w,
		img/secteur/HD_ndpmig_c_scale_w_480.jpg 480w"
		data-src="img/secteur/HD_ndpmig_c_scale_w_480.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Page>
</template>

<script>
	export default {
		name: 'hydrodesulfuration',
		components: {
			Page: () => import('@/components/Page.vue'),
		},
	}
</script>
